export function formatCurrency(
  symbol: string,
  amount: number,
  showPositive?: boolean,
) {
  const formattedAmount = amount.toFixed(2)
  if (amount < 0) {
    return `-${symbol}${Math.abs(amount).toFixed(2)}`
  } else if (amount > 0 && showPositive) {
    return `+${symbol}${formattedAmount}`
  } else {
    return `${symbol}${formattedAmount}`
  }
}