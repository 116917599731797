import React, { useState, Fragment, useContext, useEffect } from 'react'
import axios from 'axios';
import { MetaTags } from 'react-meta-tags';
import { useHistory } from 'react-router-dom';
import { GET_USER_BUDGET_HISTORY, X_TENANT_ID } from '../../common/ActionUrl';
import { AuthContext } from '../../common/Auth';
import Loader from '../../common/Loader';
import MainBreadCrumb from '../../wrappers/breadcrumb/MainBreadCrumb';
import moment from 'moment';

const Budget = () => {
    const history = useHistory();
    const [loader, setLoader] = useState(false)
    const { tabName, userBudget, themeSettingsData, paymentMethodsSettings, user } = useContext(AuthContext);
    const [userId, setUserId] = useState(user?.userId);
    const [budgetListHistory, setBudgetListHistory] = useState([])
    const [budgetSettings, setBudgetSettings] = useState([])

    const [breadCrumbProps, setBreadCrumbProps] = useState({
        firstLabel: "Home",
        firstUrl: "/",
        secondLabel: "budget",
        pageName: "budget"
    })

    const budgetHistory = async (userId) => {
        setLoader(true)
        const requestOptions = {
            headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID },
        }
        axios.get(GET_USER_BUDGET_HISTORY + userId, requestOptions)
            .then((res) => {
                setLoader(false)
                if (res.data.length > 0) {
                    setBudgetListHistory(res.data)
                }
            }).catch(err => {
                console.log('err', err);
            });

    }

    useEffect(() => {
        if (userId) {
            budgetHistory(userId)
        }

    }, [userId]);

    useEffect(() => {
        paymentMethodsSettings !== undefined && paymentMethodsSettings.map((val => {
            if (val.settingsName === 'budgetSettings') {
                setBudgetSettings(val?.values[0])
            }
        }))
    }, [paymentMethodsSettings])


    return (
        <Fragment>
            <MetaTags>
                <title>{tabName}</title>
                <meta name="description" content="Budget page of flone react minimalist eCommerce template." />
            </MetaTags>
            <MainBreadCrumb
                breadCrumbProps={breadCrumbProps}
            />
            {loader && <Loader />}

            <div className="cart-main-area pt-90 pb-100">
                <div className="container">

                    <Fragment>
                        <h1 className="cart-page-title" style={{ fontWeight: "500" }}>My {budgetSettings?.budgetNameChange || 'Budget'}</h1>
                        <h3 className='points-balance'>Current {budgetSettings?.budgetNameChange || 'Budget'} balance : ${userBudget?.amount ? (userBudget?.amount)?.toFixed(2) : 0.00}</h3>
                        <div className="row">
                            <div className="col-12">
                                <div className="table-content table-responsive cart-table-content budget-table">
                                    <table className='points-table' >
                                        <thead >
                                            <tr className='points-head'>
                                                <th>Transaction</th>
                                                <th>Amount</th>
                                                <th>Remaining Balance</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        {budgetListHistory && budgetListHistory.length > 0 ?
                                            <tbody>
                                                <>{budgetListHistory.map((budgetItem, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td >
                                                                {budgetItem?.action == "budgetAdd" ? "Added" : (budgetItem?.action == "budgetRemove" ? "Subtract" :(budgetItem?.orderNo))}
                                                            </td>
                                                            <td >
                                                                <span className={budgetItem?.action == "budgetAdd" ? 'text-success' : 'error'}>{budgetItem?.action == "budgetAdd" ? "" : '-'}${Number(Math.abs(budgetItem?.amount))?.toFixed(2)}</span>
                                                            </td>
                                                            <td>
                                                                <span>${(budgetItem?.remainingBalance)?.toFixed(2)}</span>
                                                            </td>
                                                            <td >
                                                                {moment(budgetItem?.createdAt).format("MMM DD YYYY")}<br/>
                                                                {moment(budgetItem?.createdAt).format("HH:mm:ss")}
                                                            </td>
                                                        </tr>
                                                    );
                                                })} </>
                                            </tbody>
                                            :
                                            <tbody>

                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className="item-empty-area text-center" style={{ fontSize: 20 }}>
                                                            {budgetSettings?.budgetNameChange || 'Budget'} History Not Found<br />{' '}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Fragment>

                </div>
            </div>
        </Fragment>
    )
}

export default Budget