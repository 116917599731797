import React from 'react'
import { OrderPayment } from '../../../models/Payment'
import { Alert, Button, Card, CardContent, Divider, Stack, Typography } from '@mui/material'
import {
  PAYMENT_ICONS,
  PAYMENT_NAMES,
  PaymentMethodType,
} from '../../../models/PaymentMethodType'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectCurrency,
  selectSettings,
} from '../../../redux/selectors/checkoutSelectors'
import PassiveRow from '../PassiveRow'
import { getAddressString } from '../../../helpers/checkout'
import { useGetPaymentSettingsQuery } from '../../../redux/api/paymentApi'
import Decimal from 'decimal.js'
import PaymentSummaryBudgetInfo from './PaymentSummaryBudgetInfo'

interface PaymentSummaryCardProps {
  payment: OrderPayment
  onEdit?: () => void
}

export default function PaymentSummaryCard({
  payment,
  onEdit,
}: PaymentSummaryCardProps) {
  const settings = useAppSelector(selectSettings)
  const currency = useAppSelector(selectCurrency)

  const { data: paymentSettings } = useGetPaymentSettingsQuery()

  return (
    <Card>
      <Stack direction={'column'}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          p={2}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            {PAYMENT_ICONS[payment.methodType]}
            <Typography variant="body1" fontSize={'1.15rem'}>
              {PAYMENT_NAMES[payment.methodType]}
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Typography variant="body1">
              {currency.currencySymbol}
              {new Decimal(payment.amount).toFixed(2)}
            </Typography>
            {onEdit && (
              <Button variant={'outlined'} onClick={onEdit}>
                Edit
              </Button>
            )}
          </Stack>
        </Stack>
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <PassiveRow
              field={'Contact'}
              values={[
                `${payment.contact.firstName} ${payment.contact.lastName}`,
                payment.contact.email,
                payment.contact.phone,
              ]}
            />
            {!settings.isBillingAddressHidden && (
              <PassiveRow
                field={'Billing Address'}
                values={[
                  payment.address.locationName,
                  getAddressString(payment.address),
                ]}
              />
            )}
            {payment.methodType === PaymentMethodType.PURCHASE_ORDER && (
              <PassiveRow field={'PO Number'} value={payment.poNumber} />
            )}
            {payment.methodType === PaymentMethodType.GIFT_CARD && (
              <PassiveRow field={'Gift Card Code'} value={payment.giftCardCode} />
            )}
            {payment.methodType === PaymentMethodType.GIFT_CARD &&
              payment.validationResponse?.amount !== undefined && (
                <PassiveRow
                  field={'Gift Card Balance'}
                  value={`${
                    currency.currencySymbol
                  }${payment.validationResponse.amount.toFixed(2)}`}
                />
              )}
            {(payment.convenienceFee ?? 0) > 0 && (
              <PassiveRow
                field={'Convenience Fee'}
                value={`${currency.currencySymbol}${payment.convenienceFee}`}
              />
            )}
            {payment.methodType === PaymentMethodType.BUDGET && payment.budgetId ? (
              <PaymentSummaryBudgetInfo budgetId={payment.budgetId} />
            ) : undefined}
            {payment.methodType === PaymentMethodType.CARDCONNECT &&
              paymentSettings?.find(
                (s) => s.methodType === PaymentMethodType.CARDCONNECT,
              )?.testModeEnabled && (
                <Alert severity={'error'}>Test mode is enabled</Alert>
              )}
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  )
}
