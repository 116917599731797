import React from 'react'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid2,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { useAppSelector } from '../../redux/hooks'
import {
  selectConfirmationHtml,
  selectContactInfo,
  selectCurrency,
  selectOrder,
  selectOrderNo,
  selectParams,
  selectTransactionIds,
} from '../../redux/selectors/checkoutSelectors'
import parse from 'html-react-parser'
import { useGetConfirmationSettingsQuery } from '../../redux/api/checkoutApi'
import { FulfillmentMethodType } from '../../models/FulfillmentMethod'
import { getAddressString, getEtaString } from '../../helpers/checkout'
import { AetherItemType } from '../../models/OrderItem'
import { ItemListItem } from './ItemListItem'
import { PAYMENT_NAMES } from '../../models/PaymentMethodType'
import { useHistory } from 'react-router-dom'
import { OrderHoldStatus } from '../../models/Order'

export default function ConfirmationCard() {
  const params = useAppSelector(selectParams)
  const orderNo = useAppSelector((state) => selectOrderNo(state, params))
  const contactInfo = useAppSelector((state) =>
    selectContactInfo(state, params),
  )
  const transactionIds = useAppSelector((state) =>
    selectTransactionIds(state, params),
  )
  const order = useAppSelector((state) => selectOrder(state, params))
  const confirmationText = useAppSelector((state) =>
    selectConfirmationHtml(state, params),
  )

  const history = useHistory()

  const currency = useAppSelector(selectCurrency)

  const { data: confirmationSettings, isLoading: loadingSettings } =
    useGetConfirmationSettingsQuery()

  const handleBackToHome = () => {
    history.push('/')
  }

  const fulfillment = order?.aetherData.fulfillments?.[0]

  const fProcessWord =
    fulfillment?.method?.type === FulfillmentMethodType.PICKUP
      ? 'Pickup'
      : 'Shipping'
  const fCompleteWord =
    fulfillment?.method?.type === FulfillmentMethodType.PICKUP
      ? 'Pickup'
      : 'Delivery'

  return (
    <Stack spacing={2}>
      <Card>
        {loadingSettings && <Skeleton variant="rounded" height={200} />}
        {!loadingSettings && (
          <CardContent>
            <Stack spacing={2}>
              <Stack>
                <Typography variant="h4" mb={4}>
                  {confirmationSettings?.headerText}
                </Typography>
                {confirmationSettings?.showOrderNumber && (
                  <ConfirmationCardRow label="Order Number" value={orderNo} />
                )}
                {confirmationSettings?.showTransactionId && (
                  <ConfirmationCardRow
                    label="Transaction ID"
                    value={transactionIds.join(', ')}
                  />
                )}
                {confirmationSettings?.showPaymentMethodHtml &&
                  confirmationText && (
                    <Box mt={2} mb={2}>
                      <Typography variant="h6">
                        {confirmationText && parse(confirmationText)}
                      </Typography>
                    </Box>
                  )}
                {confirmationSettings?.html && (
                  <Box mt={2} mb={2}>
                    <Typography variant="h6">
                      {confirmationSettings?.html &&
                        parse(confirmationSettings.html)}
                    </Typography>
                  </Box>
                )}
              </Stack>
              {confirmationSettings?.showOrderApprovalStatus && (
                <>
                  {order?.aetherData?.holdStatus === OrderHoldStatus.HOLD &&
                    confirmationSettings?.holdApprovalStatusText && (
                      <Alert severity="warning">
                        {confirmationSettings?.holdApprovalStatusText}
                      </Alert>
                    )}
                  {order?.aetherData?.holdStatus === OrderHoldStatus.NO_HOLD &&
                    confirmationSettings?.noHoldApprovalStatusText && (
                      <Alert severity="success">
                        {confirmationSettings?.noHoldApprovalStatusText}
                      </Alert>
                    )}
                </>
              )}
              <Stack>
                {confirmationSettings?.showOrderDetails && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Order Details
                    </Typography>
                    <ConfirmationCardRow
                      label="Name"
                      value={`${contactInfo.firstName} ${contactInfo.lastName}`}
                    />
                    <ConfirmationCardRow label="Email" value={contactInfo.email} />
                    {fulfillment && (
                      <>
                        {fulfillment.method?.name && (
                          <ConfirmationCardRow
                            label={`${fProcessWord} Method`}
                            value={fulfillment.method.name}
                          />
                        )}
                        {confirmationSettings.showFulfillmentEstimate && (
                          <ConfirmationCardRow
                            label={`Estimated ${fCompleteWord} Date`}
                            value={getEtaString(fulfillment)}
                          />
                        )}
                        {fulfillment.address && (
                          <ConfirmationCardRow
                            label={`${fProcessWord} Address`}
                            value={getAddressString(fulfillment.address)}
                          />
                        )}
                        {fulfillment.method?.instructions && (
                          <ConfirmationCardRow
                            label={`${fProcessWord} Instructions`}
                            value={fulfillment.method.instructions}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </Stack>
              {confirmationSettings?.showOrderItems && (
                <Stack>
                  <Typography variant="h6">Items</Typography>
                  <Grid2 container>
                    <Grid2 size={12}>
                      {order?.items
                        ?.filter(
                          (i) =>
                            i.aetherData.aetherItemType ===
                            AetherItemType.PRODUCT,
                        )
                        ?.map((item, idx) => [
                          <ItemListItem
                            key={idx}
                            big
                            item={item}
                            data-testid={'listItem' + idx}
                          />,
                        ])}
                    </Grid2>
                    <Grid2 size={12} m={2}>
                      <Divider />
                    </Grid2>
                    <Grid2 offset={8} size={4}>
                      <Stack pr={2}>
                        {(order?.aetherData?.displayShipping ?? 0) > 0 && (
                          <ConfirmationCardRow
                            label="Shipping"
                            right
                            value={`${
                              currency.currencySymbol
                            }${order?.aetherData?.displayShipping?.toFixed(2)}`}
                          />
                        )}
                        {(order?.aetherData?.displayConvenienceFee ?? 0) >
                          0 && (
                          <ConfirmationCardRow
                            label="Convenience Fee"
                            right
                            value={`${
                              currency.currencySymbol
                            }${order?.aetherData?.displayConvenienceFee?.toFixed(
                              2,
                            )}`}
                          />
                        )}
                        {(order?.aetherData?.discount ?? 0) > 0 && (
                          <ConfirmationCardRow
                            label="Discount"
                            right
                            value={`-${
                              currency.currencySymbol
                            }${order?.aetherData?.discount?.toFixed(2)}`}
                          />
                        )}
                        <ConfirmationCardRow
                          label="Subtotal"
                          right
                          value={`${
                            currency.currencySymbol
                          }${order?.aetherData?.displaySubtotal?.toFixed(2)}`}
                        />
                        <ConfirmationCardRow
                          label="Tax"
                          right
                          value={`${
                            currency.currencySymbol
                          }${order?.aetherData?.displayTax?.toFixed(2)}`}
                        />
                        <ConfirmationCardRow
                          label="Total"
                          right
                          value={`${
                            currency.currencySymbol
                          }${order?.aetherData?.displayTotal?.toFixed(2)}`}
                        />
                      </Stack>
                    </Grid2>
                  </Grid2>
                </Stack>
              )}
              <Stack>
                {confirmationSettings?.showPayments && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Payments
                    </Typography>
                    {order?.aetherData.payments?.map((payment, idx) => (
                      <ConfirmationCardRow
                        key={idx}
                        label={`${PAYMENT_NAMES[payment.methodType]}`}
                        value={`${
                          currency.currencySymbol
                        }${payment.amount.toFixed(2)}`}
                      />
                    ))}
                  </>
                )}
              </Stack>
            </Stack>
          </CardContent>
        )}
      </Card>
      <Button variant={'outlined'} onClick={handleBackToHome}>
        Back to home page
      </Button>
    </Stack>
  )
}

function ConfirmationCardRow({
  label,
  value,
  right,
}: {
  label: string
  value?: string
  right?: boolean
}) {
  if (value) {
    return (
      <Grid2 container>
        <Grid2 size={4}>
          <Typography variant="body1">{label}:</Typography>
        </Grid2>
        <Grid2 size={8}>
          <Typography variant="body1" textAlign={right ? 'right' : undefined}>
            {value}
          </Typography>
        </Grid2>
      </Grid2>
    )
  }
  return <></>
}
