import React from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { useGetBudgetHistoryQuery } from '../../redux/api/budgetApi'
import moment from 'moment'
import {
  BalanceUpdateType,
  BUDGET_HISTORY_EVENT_TYPE_MAP,
  BudgetHistory,
} from '../../models/NewBudget'
import { useAppSelector } from '../../redux/hooks'
import { getError } from '../../helpers/checkout'
import { formatCurrency } from '../../helpers/currency'

interface BudgetHistoryDialogProps {
  id: string
  open: boolean
  onClose: () => void
}

const getAmountText = (history: BudgetHistory, symbol: any) => {
  if (history.type === BalanceUpdateType.ADJUSTMENT) {
    return `Adjust by ${formatCurrency(
      symbol,
      history.adjustmentAmount ?? 0,
      true,
    )}`
  } else if (history.type === BalanceUpdateType.RESET) {
    return `Reset to ${formatCurrency(symbol, history.resetAmount ?? 0)}`
  }
}

export default function BudgetHistoryDialog({
  id,
  open,
  onClose,
}: BudgetHistoryDialogProps) {
  const currency = useAppSelector((state) => state.currencyData)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const {
    data: historyRes,
    isLoading,
    isSuccess,
    error,
  } = useGetBudgetHistoryQuery({
    id,
    request: {
      page,
      pageSize: rowsPerPage,
    },
  })

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      closeAfterTransition
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Budget History</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{getError(error)}</Alert>}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Event Type</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableCell colSpan={4}>
                  <Box
                    height={200}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              )}
              {historyRes?.data.map((history) => (
                <TableRow key={history._id}>
                  <TableCell>
                    {moment(history.timestamp).format('M/D/YYYY h:mm A')}
                  </TableCell>
                  <TableCell>{history.description}</TableCell>
                  <TableCell>
                    {BUDGET_HISTORY_EVENT_TYPE_MAP[history.eventType]}
                  </TableCell>
                  <TableCell>
                    {getAmountText(history, currency.currencySymbol)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={historyRes?.total ?? -1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
